import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { UserType } from "../types";
import { useApi } from "../hooks/useApi";
import { Spinner } from "@chakra-ui/react";

const CurrentUserContext = createContext<CurrentUserContextType>({
    user: null,
    isAdmin: false,
    isCustomerAdmin: false,
    actingAs: null,
});

interface CurrentUserContextType {
    user: UserType | null;
    isAdmin: boolean;
    isCustomerAdmin: boolean,
    actingAs: string | null;
}

interface CurrentUserProviderProps {
    children: ReactNode;
}

export function CurrentUserProvider({ children } : CurrentUserProviderProps) {
    const {getCurrentUser, getActingAsFocus, ready: apiReady} = useApi();
    const [actingAs, setActingAs] = useState<string | null>(null);
    const [user, setUser] = useState<UserType | null>(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isCustomerAdmin, setIsCustomerAdmin] = useState(false);
    const [ready, setReady] = useState(false); 
    
    async function fetchUser() {
        try {
            const userData = await getCurrentUser();
            setUser(userData);
            setIsAdmin(!!Number(userData.admin));
            setIsCustomerAdmin(!!(Number(userData.customeradmin)));
        } catch (err) {
            console.error(err);
        }
    }

    async function fetchActingAsFocus() {
        try {
          const actingAsFocus = await getActingAsFocus();

          if (actingAsFocus) 
            setActingAs(actingAsFocus);
        } catch (err) {
          console.log("Could not load acting as focus.");
        }
    }

    useEffect(() => {
        async function loadCurrentUser() {
            await Promise.all([fetchUser(), fetchActingAsFocus()]);
            setReady(true);
        }

        if (apiReady)
            loadCurrentUser();
    }, [apiReady]);

    if (!ready) {
        return (
            <Spinner size="xl" />
        );
    }

    return (
        <CurrentUserContext.Provider value={{ user, isAdmin, isCustomerAdmin, actingAs }}>
            {children}
        </CurrentUserContext.Provider>
    );
}

export function useCurrentUser() {
    return useContext(CurrentUserContext);
}
