import { FaArrowTrendDown, FaArrowTrendUp, FaEquals } from "react-icons/fa6";
import { Box, Center, Grid, GridItem, Heading, HStack, Skeleton, Text, VStack } from "@chakra-ui/react"
import { useApi } from "../../../hooks/useApi";
import { NotificationContext } from "../../../components/NotificationPane";
import { useContext, useEffect, useState } from "react";
import { NotificationContextType } from "../../../types";
import moment from "moment";

export type trendDataType = {
  period_end : string;
  kwh : number;
}

function TrendModule({utility}: {utility: string}) {
  const { ready, getTrends } = useApi();
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
  const [trendsData, setTrendsData] = useState<trendDataType[] | undefined>(undefined);

  // !!! reloads the module api call when resizing the window
  useEffect(() => {
    if (ready && utility)
      loadTrends();
  }, [ready, utility, getTrends]);

  async function loadTrends() {
    try {
      const responseJson = await getTrends(utility);
      setTrendsData(responseJson);

    } catch (err) {
      pushNotification("Could not load trends.", "error");
    }
  }

  if (!trendsData) return (
    <Box p={4} minW={350}>
      <VStack>
        <Skeleton height={"36px"} width={"100%"}/>
        <Skeleton height={"36px"} width={"100%"}/>
        <Skeleton height={"36px"} width={"100%"}/>
        <Skeleton height={"36px"} width={"100%"}/>
      </VStack>
    </Box>
  )

  if (trendsData.length < 2 || trendsData[1].kwh <= 0 || trendsData[0].kwh <= 0) return <Center>Not enough data.</Center>

  // trend = (current - base) / base
  const percentageTrend = ((trendsData[1].kwh - trendsData[0].kwh) / trendsData[0].kwh)*100;

  const percentageTrendDisplay = percentageTrend.toFixed(2);
  const baseConsumptionDisplay = Math.round(trendsData[0].kwh).toLocaleString();
  const currentConsumptionDisplay = Math.round(trendsData[1].kwh).toLocaleString();
  const baseMonthDisplay = moment(`${trendsData[0].period_end}-01`).format('MMM-YY');
  const currentMonthDisplay = moment(`${trendsData[1].period_end}-01`).format('MMM-YY');

  const baseText = `${baseMonthDisplay}\n${baseConsumptionDisplay}`
  const currentText = `${currentMonthDisplay}\n${currentConsumptionDisplay}`

  // !!! minor display bug of last gridItem display when on small to medium sized window, not properly structured text
  return(
    <Box>
      <Grid templateColumns='repeat(2, 1fr)' gap={4} padding={4} margin={4}>
        <GridItem><Center><Heading size='xl'>{currentConsumptionDisplay}</Heading></Center></GridItem>
        <GridItem><Center><Heading size='lg'>
          {percentageTrendDisplay}% <Center><TrendIcon trend={Number(percentageTrendDisplay)} /></Center>
        </Heading></Center></GridItem>
        <GridItem colSpan={2}>
          <HStack m={2}>
            <Text as='i' whiteSpace="pre-line"  marginRight={8}>{baseText}</Text>
            <Text as='i' whiteSpace="pre-line"> {currentText}</Text>
          </HStack>
        </GridItem>
      </Grid>
    </Box>
  )
}

function TrendIcon({trend}: {trend: number}){
  let icon;
  if (trend > 0) icon = <FaArrowTrendUp color="red" />
  else if (trend < 0) icon = <FaArrowTrendDown color="green" />
  else icon = <FaEquals color="grey" />
  return icon;
}

export default TrendModule