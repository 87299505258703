import { useContext, useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from "react-leaflet-markercluster";
import "leaflet/dist/leaflet.css"; // Mandatory CSS required by Leaflet
import "react-leaflet-markercluster/dist/styles.min.css"; // Mandatory CSS required by Leaflet MarkerCluster
import { NotificationContextType, SupplyType } from '../../types';
import { NotificationContext } from '../../components/NotificationPane';
import { useApi } from '../../hooks/useApi';
import SupplyDrawer from '../../components/SupplyDrawer';
import { Link, Spinner, useDisclosure } from '@chakra-ui/react';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import "../../styles/interactiveMap.css";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [28, 46],
    iconAnchor: [17, 46],
    popupAnchor: [-3, -37]
});

L.Marker.prototype.options.icon = DefaultIcon;

function InteractiveMap() {

  const [suppliesData, setSuppliesData] = useState<SupplyType[] | undefined>(undefined);
  const { ready, getSupplies } = useApi();
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;

  useEffect(() => {
    async function loadData() {
      try {
        const responseJson = await getSupplies();
        setSuppliesData(responseJson);
      } catch (err) {
        pushNotification("Could not load data.", "error")
      }
    }
    if (ready) loadData();
  }, [getSupplies, ready]);

  if (suppliesData === undefined) return <Spinner />

  // !!! Object.groupBy throws typescript error but no issue with functionality - think its a version issue since a new function
  // @ts-expect-error !!!apparently still an experimental method - maybe worth using custom implementation?
  const groupedSupplies: Record<string, SupplyType[]> = Object.groupBy(suppliesData, ({site_address} : {site_address: string}) => site_address)

  return (
    <MapContainer center={[54.5032, -3.4053]} zoom={6} style={{ height: "100%", borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px", zIndex: 1 }}>
      <TileLayer
        attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://tiles.stadiamaps.com/tiles/stamen_toner_lite/{z}/{x}/{y}{r}.png?api_key=cc647550-5ea4-4ab4-9668-198dfe229c33"
      />
      <MarkerClusterGroup spiderLegPolylineOptions={{color:'#B391C3'}} >
        {        
          (groupedSupplies!==null) && Object.entries(groupedSupplies).map(([key, value]) => {
            
            if (!value[0].site_latitude || !value[0].site_longitude)
            {
              console.log("Missing lat/lon", value[0].site_id); //!!! currently ignoring missing lat/lon sites and console logging
              return;
            }

            return(
              <Marker key={key} position={[value[0].site_latitude, value[0].site_longitude]}>
                <Popup>
                  <b><u>{key}</u></b>
                  {value.map((supply)=> {
                    return <SupplyLink key={supply.supply_number} supply={supply} />
                  })}
                </Popup>
              </Marker>
            )
          })
        }
      </MarkerClusterGroup>
    </MapContainer>
  )
}

function SupplyLink({supply}: {supply: SupplyType}){
  const { isOpen, onOpen, onClose } = useDisclosure(); // tracks status of renewals drawer
  return (
    <>
      <SupplyDrawer key={supply.supply_id} id={supply.supply_id} isOpen={isOpen} onClose={onClose} />
      <Link key={supply.supply_id} onClick={onOpen}><br/>{supply.supply_verbose}</Link>
    </>
  )
}

export default InteractiveMap