import { Dispatch, SetStateAction, useCallback } from 'react';
import { Calendar, momentLocalizer, SlotInfo } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CalendarEventType } from '../../types';

type EventCalendarProps = {
  calendarEvents: CalendarEventType[] | undefined
  selectedDates: Date[]
  setSelectedDates: Dispatch<SetStateAction<Date[]>>
}

const localizer = momentLocalizer(moment);

function EventCalendar({calendarEvents, selectedDates, setSelectedDates}: EventCalendarProps) {

  function handleSlotSelect(slot: SlotInfo) {
    setSelectedDates(slot.slots)
  }
  
  function handleEventSelect(event: CalendarEventType) {
    if (event.end) setSelectedDates([event.end])
  }

  const selectedDateStrings = selectedDates.map((date) => {
    return (date.toLocaleDateString())
  })

  const highlightSelectedDates = useCallback(
    (date: Date) => ({
      ...(selectedDateStrings.includes(date.toLocaleDateString()) && {
        style: {
          backgroundColor: '#B391C3',
        },
      }),
    }), [selectedDates]
  )

  console.log(selectedDates.includes)

  return(
      <Calendar
        localizer={localizer}
        events={calendarEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '400px', width: '100%' }}
        defaultView='month'
        defaultDate={selectedDates[0]} // set default date view to the selected ced passed
        views={['month']}
        selectable
        onSelectSlot={(slot: SlotInfo) => handleSlotSelect(slot)}
        onSelectEvent={(event: CalendarEventType) => handleEventSelect(event)}
        dayPropGetter={highlightSelectedDates}
      />
  )

}

export default EventCalendar