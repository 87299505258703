import { Route } from "wouter";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { ChakraProvider } from '@chakra-ui/react'
import HHDashboard from "./pages/HHDashboard/HHDashboard";
import Admin from "./pages/Admin/Admin";
import Files from "./pages/Files";
import SiteSummary from "./pages/SiteSummary";
import Home from "./pages/Home";
import NotificationPane from "./components/NotificationPane";
import ValidUserRoute from "./util/ValidUserRoute";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import "./styles/customScrollbar.css";
import "./styles/ag-grid-custom.css";
import DynamicMenu from "./components/Sidebar";
import { extendTheme } from "@chakra-ui/react"
import { colourOverrides } from "./util/Colours";
import { CurrentUserProvider } from "./contexts/CurrentUserContext";
import { CostSummary } from "./pages/CostSummary/CostSummary";

const theme = extendTheme({
  colors: colourOverrides
})


LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-066909}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{eyebright_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Customer_Portal}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Customer_Portal}_need_to_be_licensed___{Customer_Portal}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_September_2025}____[v3]_[01]_MTc1NzYzMTYwMDAwMA==eb9b4c72adb3a66178af64f116534471");

function App({ msalInstance } : {msalInstance: PublicClientApplication }) {
  return (
    <MsalProvider instance={msalInstance }>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <ChakraProvider theme={theme} portalZIndex={2}>
          <CurrentUserProvider>
            <NotificationPane>
              <ValidUserRoute>
                <DynamicMenu>
                  <Route path="/" component={Home} />
                  <Route path="/site-summary" component={SiteSummary} />
                  <Route path="/hh-dashboard" component={HHDashboard} />
                  <Route path="/cost" component={CostSummary} />
                  <Route path="/contract-renewals" />
                  <Route path="/files" component={Files}/>
                  <Route path="/admin" component={Admin} />
                </DynamicMenu>
              </ValidUserRoute>
            </NotificationPane>
          </CurrentUserProvider>
        </ChakraProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  )
}

export default App
