import { FaCircleExclamation, FaRegCalendar } from "react-icons/fa6";
import { Box, Icon, Link, Skeleton, useDisclosure, VStack, Text, Center } from "@chakra-ui/react"
import RenewalsDrawer from "../../../components/RenewalsDrawer";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../../components/NotificationPane";
import { useApi } from "../../../hooks/useApi";
import { CalendarEventType, NotificationContextType, SupplyType } from "../../../types";
import { v4 as uuidv4 } from "uuid";

function RenewalsModule() {
  const { ready, getSupplies } = useApi();
  const [ error, setError ] = useState<boolean>(false);
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
  const [calendarEvents, setCalendarEvents] = useState<CalendarEventType[] | undefined>(undefined);

  useEffect(() => {
    if (ready)
      loadSupplies();
  }, [ready, getSupplies]);

  async function loadSupplies() {
    setCalendarEvents(undefined);
    setError(false);

    try {
      const responseJson = await getSupplies();

      const oocSupplies = responseJson.filter((supply) => {
        return supply.rollingcontract;
      });

      const filteredSupplies = responseJson.filter((supply) => {
        if (supply.rollingcontract) return; //ignore rolling as in ooc group
        const supplyDate = new Date(supply.contract_end_date);
        return supplyDate >= new Date();
      });

      const sortedSupplies = filteredSupplies.sort((a: SupplyType, b: SupplyType) => {
        const dateA = new Date(a.contract_end_date);
        const dateB = new Date(b.contract_end_date);
        return dateA.getTime() - dateB.getTime();
      });

      let events = []

      if (oocSupplies) {
        events.push({title: `${oocSupplies.length} supplies`})
      }

      // !!! Object.groupBy throws typescript error but no issue with functionality - think its a version issue since a new function
      // @ts-expect-error !!!apparently still an experimental method - maybe worth using custom implementation?
      const groupedSupplies = Object.groupBy(sortedSupplies, ({contract_end_date} : {contract_end_date: string}) => contract_end_date);
      console.log(groupedSupplies)
      for (const date in groupedSupplies) {
        const dateMoment = moment(date, 'YYYY-MM-DD').toDate()
        events.push({
          start: dateMoment,
          end: dateMoment,
          title: `${groupedSupplies[date].length} supplies due`,
        })
      }
      if (events.length > 5)
        events = events.slice(0, 5);

      setCalendarEvents(events)
    } catch (err) {
      // !!! notification context error
      setError(true);
      pushNotification("Could not load supplies.", "error");
    }
  }

  return(
    <Box>
      <VStack p={4} className="custom-scroll" alignItems={"flex-start"}>
        {
          error &&
          <Box w="100%">
            <Center><Icon as={FaCircleExclamation }/> Error loading data.</Center>
          </Box>
        }
        {
          !error && calendarEvents && calendarEvents.length === 0 &&
          <Box w="100%">
            <Center>Not enough data.</Center>
          </Box>
        }
        {
          !error && calendarEvents && calendarEvents.length > 0 &&
            calendarEvents.map((event: CalendarEventType, index: number) => (
                <RenewalsLink key={uuidv4()} ced={event.end} title={event.title} indent={index !== 0}/>
            ))
        }
        {
          !error && !calendarEvents &&
          <>
            <Skeleton height={"36px"} width={"100%"}/>
            <Skeleton height={"36px"} width={"100%"}/>
            <Skeleton height={"36px"} width={"100%"}/>
            <Skeleton height={"36px"} width={"100%"}/>
          </>
        }
      </VStack>
    </Box>
  )
}

function RenewalsLink({ced, title, indent}: {ced?: Date, title: string, indent: boolean}){
  const { isOpen, onOpen, onClose } = useDisclosure(); // tracks status of renewals drawer
  const [dateString, setDateString] = useState<string>("");
  const [daysToRenewal, setDaysToRenewal] = useState<number>(0);
  
  useEffect(() => { 
    if (ced) {
      setDateString(moment(ced).format("DD/MM/YYYY"));

      const now = new Date();
      const diff = ced.getTime() - now.getTime();
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      setDaysToRenewal(days);
    }
  }, [ced])

  if (!ced) return (
    <>
      <RenewalsDrawer isOpen={isOpen} onClose={onClose} ced={null} />
      <Link marginLeft={indent ? 5: 0} onClick={onOpen} fontSize={indent ? 'medium' : 'large'}>
        {daysToRenewal <= 180 ? <Icon as={FaCircleExclamation }/> : ""} <Icon as={FaRegCalendar}/> <Text as={'b'}>Out of Contract</Text> {title}
      </Link>
    </>
  )

  return (
    <>
      <RenewalsDrawer isOpen={isOpen} onClose={onClose} ced={ced} />
      <Link
        marginLeft={indent ? 5: 0} // Dynamic indentation
        onClick={onOpen}
        fontSize={indent ? 'medium' : 'large'} // Dynamic font size
      >
        {daysToRenewal <= 180 ? <Icon as={FaCircleExclamation }/> : ""} <Icon as={FaRegCalendar}/> <Text as={'b'}>{(dateString)}</Text> {title}
      </Link>
    </>
  )
}

export default RenewalsModule