import { Box, Text, Image } from "@chakra-ui/react"
import eyebrightLogo from '../../../assets/eyebright-logo.png';

function BannerModule() {
  return (
    <Box height='100%' backgroundColor={'#6A7D80'} borderBottomRadius="12px" style={{position:'relative'}}>
      <Image src={eyebrightLogo} alt="Eyebright Logo" position='absolute' margin={{base: 4, md: 8}} bottom='0' left={0} w={{base: 180, sm: 250, md: 400}} />
      <Text color={"white"} fontFamily={'sansation'} fontSize={{base: 14, sm: 18}} width={"25%"} style={{position:'absolute', bottom:'0', right:'0', margin:32}}>We focus on your utilities.</Text>
    </Box>
  )
}

export default BannerModule