import { useContext, useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";
import { costSummaryType, NotificationContextType } from "../../types";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { Box, Spinner, Text } from "@chakra-ui/react";
import { NotificationContext } from "../../components/NotificationPane";
import moment from "moment";
import { presetColors } from "../../util/Colours";
import { useSelectedAccount } from "../../hooks/useSelectedAccount";

function prepareData(data: costSummaryType[]): { sortedData: { month: Date }[], utilityTypes: string[] } {
    // Initialize an object to group data by month and a Set to track unique utility types
    const groupedData: Record<string, { month: Date; [key: string]: number | Date }> = {};
    const utilityTypeSet = new Set<string>();

    data.forEach(item => {
        const monthKey = new Date(item.month).toISOString(); // Unique key for each month
        const monthDate = new Date(monthKey);

        // Initialize the month entry if it doesn't exist
        if (!groupedData[monthKey]) {
            groupedData[monthKey] = { month: monthDate };
        }

        // Normalize and add utility type to both the entry and the Set of unique types
        const utilityTypeKey = item.utility_type.toLowerCase();
        groupedData[monthKey][utilityTypeKey] = item.net_bill;

        // Add utility type to the Set to ensure uniqueness
        utilityTypeSet.add(utilityTypeKey);
    });

    // Convert grouped data into an array and sort by date
    const sortedData = Object.values(groupedData).sort((a, b) => a.month.getTime() - b.month.getTime());

    // Convert the Set of unique utility types into an array
    const utilityTypes = Array.from(utilityTypeSet);

    return { sortedData, utilityTypes };
}


export function CostSummaryGraph() {
    const { ready, getAccountCostSummary } = useApi();
    const [loading, setLoading] = useState(true);
    const [utilities, setUtilities] = useState<string[]>([]);
    const [costSummary, setCostSummary] = useState<{ month: Date; [key: string]: number | Date }[] | null>(null);
    const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;
    const { selectedAccount } = useSelectedAccount();

    useEffect(() => {
        async function loadSummary() {
            if (!selectedAccount) return;
            setLoading(true);
            try {
                const data = await getAccountCostSummary(selectedAccount);
                const { sortedData, utilityTypes } = prepareData(data);
                setUtilities(utilityTypes);
                setCostSummary(sortedData);
            } catch (err) {
                pushNotification("Could not load cost data.", "error");
            }
            setLoading(false);
        }

        if (ready && selectedAccount) {
            loadSummary();
        }
    }, [ready, getAccountCostSummary, selectedAccount]);

    const dateFormatter = (value : any) => {
        return moment(value).format('MMM YYYY')
    };

    const currencyFormatter = (value : any) => {
        return `£${value}`;
    };

    if (loading) return <Spinner /> ;

    if (!loading && (!costSummary || costSummary.length === 0)) return <Text>Not enough data.</Text>;

    if (costSummary) return (
        <Box width="100%" height="100%">
            <Text fontSize="xl" mb={4} textAlign="center">Monthly Invoiced Costs</Text>
            <ResponsiveContainer width="100%" height="90%">
                <BarChart margin={{left: 40 }} data={costSummary}>
                    <XAxis dataKey="month" tickFormatter={dateFormatter} />
                    <YAxis tickFormatter={currencyFormatter}/>
                    <Tooltip formatter={currencyFormatter} labelFormatter={dateFormatter} />
                    <Legend />
                    {
                        utilities.map((item, index) => (
                            <Bar key={index} dataKey={item} name={item} fill={presetColors[index]} />
                        ))
                    }
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
}
