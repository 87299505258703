import { Box, Divider, Grid, GridItem, Skeleton, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { useApi } from "../../../hooks/useApi";
import { useEffect, useState } from "react";
import { AccountSummary as AccountSummaryType } from "../../../types";
import SupplyIcon from "../../../components/SupplyIcon";

function AccountSummary() {
  const { ready, getAccountSummary } = useApi();
  const [accountSummary, setAccountSummary] = useState<AccountSummaryType | null>(null);

  useEffect(() => {
    async function loadSummary() {
      setAccountSummary(null);
      try {
        const summary = await getAccountSummary();
        if (summary.length > 0) setAccountSummary(summary[0]);
      } catch (err) {
        console.error(err);
      }
    }

    if (ready) loadSummary();
  }, [ready, getAccountSummary]);

  return (
    <Box h={'315'}  minW={350}>
      <Grid templateColumns='repeat(5, 1fr)' gap={4} padding={2} margin={2}>
        <GridItem colSpan={2}>
          <Box border={"1px"} borderRadius={"12px"} borderColor={"#E2E8F0" } boxShadow={"sm"}>
            <Stat m={2}>
              <StatLabel>Active Sites</StatLabel>
              <StatNumber>{accountSummary ? accountSummary.site_count.toLocaleString() : <Skeleton height={"36px"}/>}</StatNumber>
            </Stat>
          </Box>
        </GridItem>
        <GridItem colSpan={3}>
          <Box border={"1px"} borderRadius={"12px"} borderColor={"#E2E8F0" } boxShadow={"sm"}>
            <Stat m={2}>
              <StatLabel>Total Consumption</StatLabel>
              <StatNumber>{accountSummary ? accountSummary.consumption_total.toLocaleString() : <Skeleton height={"36px"}/>}</StatNumber>
            </Stat>
          </Box>
        </GridItem>
        <GridItem colSpan={5}><Divider /></GridItem>
        <GridItem colSpan={2}>
          <Box border={"1px"} borderRadius={"12px"} borderColor={"#E2E8F0" } boxShadow={"sm"}>
            <Stat m={2}>
              <StatLabel><SupplyIcon utility={"Electricity"} /> Elec Meters</StatLabel>
              <StatNumber>
                {accountSummary ? accountSummary.site_count_e.toLocaleString() : <Skeleton height={"36px"}/>}
              </StatNumber>
            </Stat>
          </Box>
        </GridItem>
        <GridItem colSpan={3}>
          <Box border={"1px"} borderRadius={"12px"} borderColor={"#E2E8F0" } boxShadow={"sm"}>
            <Stat m={2}>
              <StatLabel><SupplyIcon utility={"Electricity"} /> Elec Consumption</StatLabel>
              <StatNumber>
                {accountSummary ? accountSummary.consumption_total_e.toLocaleString() : <Skeleton height={"36px"}/>}
              </StatNumber>
            </Stat>
          </Box>
        </GridItem>
        <GridItem colSpan={2}>
          <Box border={"1px"} borderRadius={"12px"} borderColor={"#E2E8F0" } boxShadow={"sm"}>
            <Stat m={2}>
              <StatLabel><SupplyIcon utility={"Gas"} /> Gas Meters</StatLabel>
              <StatNumber>
                {accountSummary ? accountSummary.site_count_g.toLocaleString() : <Skeleton height={"36px"}/>}
              </StatNumber>
            </Stat>
          </Box>
        </GridItem>
        <GridItem colSpan={3}>
          <Box border={"1px"} borderRadius={"12px"} borderColor={"#E2E8F0" } boxShadow={"sm"}>
            <Stat m={2}>
              <StatLabel><SupplyIcon utility={"Gas"} /> Gas Consumption</StatLabel>
              <StatNumber>
                {accountSummary ? accountSummary.consumption_total_g.toLocaleString() : <Skeleton height={"36px"}/>}
              </StatNumber>
            </Stat>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default AccountSummary;
