import AccountSummary from "./Home/Modules/AccountSummary"
import ConsultantContact from "./Home/Modules/ConsultantContact"
import InteractiveMap from "./Home/InteractiveMap"
import TrendModule from "./Home/Modules/TrendModule";
import RenewalsModule from "./Home/Modules/RenewalsModule";
import FilesModule from "./Home/Modules/FilesModule";
import HomeTemplate from "../components/HomeTemplate";
import SupplyIcon from "../components/SupplyIcon";
import BannerModule from "./Home/Modules/BannerModule";

function Home() {
  return (
    <HomeTemplate>
      <HomeTemplate.Module title="eyebright" height={{ base: 150, md: 350 }} colSpan={{ base: 2, md: 2 }}>
        <BannerModule />
      </HomeTemplate.Module>
      <HomeTemplate.Module  title="EYEBRIGHT CONTACT" height={350} colSpan={{ base: 2, md: 1 }} order={{ base: 1, md: 'inherit' }}>
        <ConsultantContact/>
      </HomeTemplate.Module>
      <HomeTemplate.Module  title="ACCOUNT SUMMARY" height={350} colSpan={{ base: 2, md: 1 }}>
        <AccountSummary />
      </HomeTemplate.Module>
      <HomeTemplate.Module icon={<SupplyIcon utility={"Electricity"}/>} title="ELECTRICITY TREND" link="hh-dashboard" height={250} colSpan={{ base: 2, md: 1 }}>
        <TrendModule utility="Electricity" />
      </HomeTemplate.Module>
      <HomeTemplate.Module icon={<SupplyIcon utility={"Gas"}/>} title="GAS TREND" link="hh-dashboard" height={250} colSpan={{ base: 2, md: 1 }}>
        <TrendModule utility="Gas" />
      </HomeTemplate.Module>
      <HomeTemplate.Module  title="SITE MAP" colSpan={{ base: 2, md: 2 }} rowSpan={{ base: 2, md: 2 }} height={{ base: 400, md: 'auto' }} order={{ base: 2, md: 'inherit' }}>
        <InteractiveMap />
      </HomeTemplate.Module>
      <HomeTemplate.Module  title="UPCOMING RENEWALS" height={250} colSpan={{ base: 2, md: 1 }}>
        <RenewalsModule />
      </HomeTemplate.Module>
      <HomeTemplate.Module  title="RECENT FILES" height={250} colSpan={{ base: 2, md: 1 }}>
        <FilesModule />
      </HomeTemplate.Module>
    </HomeTemplate>
)
}

export default Home
