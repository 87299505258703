import { ChartTypeEnum, GroupEnum, OverlayEnum, PeriodEnum } from "./Enums";

export const periodOptions = [
    { value: PeriodEnum.HOURLY, label: 'Hourly' },
    { value: PeriodEnum.DAILY, label: 'Daily' },
    { value: PeriodEnum.WEEKLY, label: 'Weekly' },
    { value: PeriodEnum.MONTHLY, label: 'Monthly' },
    { value: PeriodEnum.YEARLY, label: 'Yearly' },
];

export const overlayOptions = [
    { value: OverlayEnum.AVERAGE, label: 'Average' },
    { value: OverlayEnum.TREND, label: 'Rolling Average' },
    { value: OverlayEnum.DAY_NIGHT, label: 'Day/Night' },
];

export const chartTypeOptions = [
    { value: ChartTypeEnum.BAR, label: 'Bar' },
    { value: ChartTypeEnum.LINE, label: 'Line' },
]

export const groupingOptions = [
    { value: GroupEnum.TOTAL, label: 'Total' },
    { value: GroupEnum.ACCOUNT, label: 'Account' },
    { value: GroupEnum.SITE_GROUP, label: 'Site Group' },
    { value: GroupEnum.SUPPLY_TYPE, label: 'Supply Type' },
]
  